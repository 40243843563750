.feedEditLink {
    text-decoration: none;
}

.cntEditIcon {
    margin-right: 10.99px;
}

.friendSearchContainer {
    display: flex;
    width: 800px;
    text-align: center;
    margin: 0px auto;
}

.contactsHeading {
    font-family: Jost-Regular, sans-serif !important;
    font-weight: 500;
    font-size: 22px;
    line-height: 32px;
    letter-spacing: 0.09em;
    text-transform: uppercase;
    color: #FFFFFF;
    margin: 0px 0px 20px 25px;
}

.contactsCount {
    font-family: Jost-Regular, sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #6A8393;
    margin: 5px 13px 0px;
}

.contactSeachContainer {
    height: 24px;
    width: 200px;
    background: #030F17;
    border: 1.4px solid #F1F2F2;
    border-radius: 42px;
}

.contactSeachContainer:hover {
    background: #012540;
}

.friendSearchContainer .cntMenuSelection {
    min-width: 28px;
    height: 28px;
    background: #030F17;
    border-radius: 4px;
    margin-left: 12px;
    margin-right: 20px;
}

.friendSearchContainer .cntMenuSelection:hover {
    background: #030F17;
}

.cntMenuIcon {
    vertical-align: middle;
    position: relative;
}

.searchInputText {
    margin-left: 10px;
    color: white !important;
    height: 16px;
    margin-top: 4px;
    width: 85%;
}

.searchInputText .MuiInputBase-input {
    font-family: Jost-Regular, sans-serif;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    color: #FFFFFF;
    padding-top: 7px;
}

.FriendPageMenuContainer {
    display: flex;
    flex-wrap: wrap;
    width: 800px;
    text-align: center;
    margin: 5px auto 30px;
}

.contactCard {
    width: 176px;
    height: 248px;
    background: #030F17;
    border-radius: 8px;
    margin-left: 18px;
    margin-bottom: 20px;
    border: 1.6px solid #0F2C40;
}

.contactCard:nth-child(4n) {
    margin-right: 0px
}

.hiddenContactCard {
    border: none !important;
}

.contactBackgroundImg {
    width: 176px;
    height: 102px;
    border-radius: 8px 8px 0px 0px;
    /* filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.5)); */
    z-index: -5;
    border-bottom: 1px solid #081B27;
}

.bannerImg {
    width: 176px;
    height: 103px;
    border-radius: 8px 8px 0px 0px;
}

.bannerNoImg {
    width: 60px;
    height: 45px;
    padding-top: 8px;
}

.contactProfilePic {
    margin-top: -52.5px;
    z-index: 0;
    position: relative;
    width: 88px;
    height: 88px;
    justify-content: center;
    display: inline-block;
}

.contactPicImg {
    width: 88px;
    height: 88px;
    border-radius: 50%;
    z-index: 5;
    border: 1.4px solid #F1F2F2;
}

.contactPicNoImg {
    height: 47px;
    border: 1px solid #081B27;
    width: 47px;
    padding: 25px;
    border-radius: 50%;
    z-index: 5;
}

.contactName {
    font-family: Jost-Regular, sans-serif;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    color: #F1F2F2;
    text-align: center;
    margin: 11px 0px 5px;
}

.contactNickName {
    font-family: Jost-Regular, sans-serif;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    color: #6A8393;
    margin: 0px 0px 11px;
}

.detailsContainer {
    /* justify-content: center; */
    /* position: relative; */
    display: flex;
}

.ideasContainer {
    width: 80px;
    height: 28px;
    background: #081B27;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    color: #F1F2F2;
}

.ideasContainer:hover {
    background: #012540;
    cursor: pointer;
}

.magnifier {
    vertical-align: middle;
    position: relative;
}

.cntIdeas {
    font-family: Jost-Regular, sans-serif;
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
    color: #F1F2F2;
    vertical-align: middle;
    position: relative;
    margin-left: 16px;
    text-decoration: none;
}

.FriendPageMenuContainer .removeContactContainer {
    min-width: 28px !important;
    height: 28px !important;
    background: #081B27;
    border-radius: 4px;
    margin: 0px 4px;
}

.removeContactContainer:hover {
    background: #012540 !important;
}

.removeCntImg {
    padding: 6px;
    width: 16px;
    height: 16px;
}

.removeCntImg:hover {
    color: #F1F2F2;
}

.contactSettingsContainer {
    width: 28px;
    height: 28px;
    background: #081B27;
    border-radius: 4px;
    margin-right: 16px;
}

.contactSettingsContainer .feedSettingsIcon {
    margin: 0px;
    vertical-align: middle;
    display: contents;
    cursor: default;
}

.contactSettingsContainer:hover {
    background: #012540
}

.hiddenUserSetting{
    background: #0F2C40;
}

.contactList {
    display: flex;
    width: 390px;
    height: 68px;
    background: #030F17;
    border-radius: 8px;
    margin-bottom: 12px;
    border: 1.6px solid #0F2C40;
}

.hiddenContactList {
    display: flex;
    width: 390px;
    height: 68px;
    background: #05131D;
    border-radius: 4px;
    margin-bottom: 12px;
}

.hiddenContactList:nth-child(2n) {
    margin-left: 12px
}

.contactList:nth-child(2n) {
    margin-left: 12px
}

.contactListPicImg {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: 1.2px solid #F1F2F2;
    margin: 10px 0px 10px 20px;
}

.hiddenContactListPicImg {
    opacity: 0.5;
}


.conatctGroupNames {
    display: flex;
    flex-direction: column;
    align-self: center;
    margin-left: 16px;
    text-align: left;
}

.hiddenContactGroupNames {
    margin-top: 12px !important;
}

.contactListName {
    font-family: Jost-Regular, sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    color: #F1F2F2;
    margin: 0px;
}

.hiddenContactListName {
    opacity: 0.5;
}

.contactListNickName {
    font-family: Jost-Regular, sans-serif;
    font-weight: 400;
    font-size: 9px;
    line-height: 13px;
    color: #6A8393;
    margin: 0px;
    overflow-wrap: break-word;
    max-width: 124px;
}

.hiddencontactListNickName {
    opacity: 0.5;
}

.cntListSettings {
    display: flex;
    margin-top: 20px;
}

.hiddenMain {
    width: "184px";
    height: "251px";
    position: "relative";
    margin: "14.44px";
}

.hiddenContainer {
    background: "#030F17";
    width: "100%";
    height: "100%";
    position: "absolute";
    top: 0;
    left: 0;
}

.hiddenIcon {
    opacity: 1;
    z-index: 999;
    top: -15px;
    position: relative;
}

.hiddenContact {
    opacity: 0.4;
}

/* .MuiTooltip-tooltip {
    background: #0D2738;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5);
    border-radius: 2px;
    font-family: Jost-Regular, sans-serif;
    font-weight: 400;
    color: #F1F2F2;
} */

.tooltipGrid {
    height: 22px !important;
    width: 99px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px !important;
    font-family: Jost-Regular, sans-serif;
    font-weight: 400;
    color: #F1F2F2;
    margin-left: 20px;
    background: #0D2738;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5);
    border-radius: 2px;
}

.txtReportedUser {
    font-family: Jost-Regular, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 7px;
    line-height: 10px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #B00002;
    margin-bottom: 0px;
}

.txtHiddenUser {
    font-family: Jost-Regular, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 7px;
    line-height: 10px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #5B7C92;
    margin-bottom: 0px;
}

.reportedContactName {
    font-family: Jost-Regular, sans-serif;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    color: #F1F2F2;
    text-align: center;
    margin: 0px;
    opacity: 0.3;
}

.btnReportedUser {
    height: 28px;
    width: 144px;
    border-radius: 4px;
    background-color: #0F2C40 !important;
    text-transform: none !important;
    margin: 0px 16px 21px 16px !important;
}

.btnReportedUser:hover {
    background-color: #13344A !important;
}

.txtReportedBtn {
    font-family: Jost-Regular, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
    color: #B7C8D2;
}

.txtReportedBtn:hover {
    color: #F1F2F2;
}

.reportedBannerImg {
    width: 176px;
    height: 103px;
    border-radius: 8px 8px 0px 0px;
    opacity: 0;
}

.reportedContactProfilePic {
    margin-top: -52.5px;
    z-index: 0;
    position: relative;
    width: 88px;
    height: 88px;
    justify-content: center;
    display: inline-block;
    opacity: 0.5;
}

.hiddeUserName {
    opacity: 0.3;
}

.contactOptions {
    font-family: Jost-Regular, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #F1F2F2;
    border: none;
    margin: 14px 0px 14px 18px;
}

.selectContact .MuiInputBase-input {
    font-family: Jost-Regular, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 27px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #f1f2f2;
    border: none;
    outline: 0px;
    background: #081B27;
}

.selectContact:before {
    border-bottom: 0px solid rgba(0, 0, 0, 0.42) !important;
}

.MuiList-root {
    background: #0D2738;
}

.MuiButton-text {
    padding: 0px !important;
}

.hiddenHeading {
    font-family: Jost-Regular, sans-serif;
    font-weight: 500;
    font-size: 7px;
    line-height: 10.12px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #5B7C92;
    margin: 0px;
}

.reportedHeading {
    color: #B00002 !important;
}

.reportIconlist {
    top: -45px;
    left: 33px;
    position: relative;
}

.MuiSelect-icon {
    fill: #f1f2f2 !important;
}

.contactMenuList {
    background: #0D2738;
    color: #F1F2F2;
}

.boxOption {
    font-family: Jost-Regular, sans-serif !important;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 19px !important;
    line-height: 27px !important;
    letter-spacing: 0.1em !important;
    text-transform: uppercase !important;
    color: #f1f2f2 !important;
    background: #081B27;
}

.boxOption select option {
    background-color: #0D2738;
    outline: none !important;
    border: none !important;
    gap: 20px;
    font-family: Jost-Regular, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #f1f2f2;
}

.boxOption select option:hover {
    background-color: #13344A;
    color: #FFFFFF
}

.selectOption {
    font-family: Jost-Regular, sans-serif !important;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 11px !important;
    line-height: 16px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #F1F2F2 !important;
    padding: 14px 18px !important;
    width: 160px !important;
}

.contactMenu .MuiListItem-root:hover {
    background-color: #13344A !important;
}

.contactMenu .MuiList-padding {
    padding: 0px;
}

.hiddedIconAndProfileImg{
    display: grid;
}