.LoadingOverlayStyle {
  position: fixed;
  z-index: 6;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.6);
}

.LoadingComponent {
  position: absolute;
  top: 30% !important;
  margin: auto;
  left: 45% !important;
}
