.termsText2 {
  font-family: Jost-Regular, Sans-serif;
  font-weight: 800;
  font-size: 12px;
  line-height: 17px;
  color: #f1f2f2;
}

.termsText2:hover {
  text-decoration: underline;
  cursor: pointer;
}

.termsPopUpContainer .MuiDialog-paperScrollPaper {
  background-color: #030f17;
  max-width: 680px;
  max-height: 620px;
  border: 1.5px solid #0F2C40;
  border-radius: 8px;
  /* max-height: calc(100% - 53%) !important; */
}

@media screen and (min-width: 768px) and (max-width: 1400px) {
  .termsPopUpContainer .MuiDialog-paperScrollPaper {
    max-height: 500px;
  }
}

@media screen and (min-width:1920px) {
  .termsPopUpContainer .MuiDialog-paperScrollPaper {
    margin-top: 150px;
  }
}

.termsPopUpContainer .MuiSvgIcon-root {
  color: #6a8393;
}

.termsPopUpContainer .MuiDialogTitle-root,
.termsPopUpContainer .MuiDialogActions-root {
  background-color: #030f17;
  color: #6a8393;
}

.termsPopUpContainer .MuiDialogTitle-root .MuiTypography-h6 {
  text-align: center;
  font-family: Jost-Regular, Sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 32px;
  letter-spacing: 0.06em;
  text-transform: uppercase;

}

.termsPopUpContainer .MuiDialogContent-root {
  /* background: #061621;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5); */
  margin: 30px;
}

.termsPopUpContainer .MuiDialogContentText-root {
  color: #ffffff;
  font-family: Jost-Regular, Sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 19px;
}

.termsPopUpContainer .MuiButton-label {
  color: #6a8393 !important;
  font-family: Jost-Regular, Sans-serif;
}

.close-icon {
  background-color: #030f17;
  color: #6a8393;
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 0 !important
}

.MuiIconButton-label {
  justify-content: end !important;
}

.termsAndContidionContent p strong {
  color: #ffffff !important;
  font-family: Jost-Regular, Sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
}

.termsAndContidionContent p span {
  color: #ffffff !important;
  font-family: Jost-Regular, Sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
}

.termsAndContidionContent p em {
  color: #ffffff !important;
  font-family: Jost-Regular, Sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
}

table {
  border: 1px solid #86A2B4;
  border-collapse: collapse;
  border-radius: 2px;
  border-style: hidden;
  box-shadow: 0 0 0 1px #86A2B4;
  margin-top: 13px;
}

th {
  border: 1px solid #86A2B4;
  font-family: Jost-Regular, Sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  text-align: center;
  color: #F1F2F2;
  padding: 10px 12px;
}

td {
  border: 1px solid #86A2B4;
  font-family: Jost-Regular, Sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  color: #F1F2F2;
  padding: 10px 12px;
}

.privacyDialog {
  padding: 0px !important;
  margin-top: 0px !important;
  margin-left: 100px !important;
}

.privacyContent {
  margin-top: 0px;
  margin-right: 65px;
}

.privacyContent span {
  font-family: Jost-Regular, Sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #FFFFFF;
}

.privacyContent ol {
  font-family: Jost-Regular, Sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: #FFFFFF;
  margin-left: -20px;
}

.privacyContent ol>li {
  margin-top: 24px;
}

.privacyContent ul {
  list-style-type: disc;
}

.privacySubOl {
  margin-top: 12px !important;
  margin-left: -15px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #FFFFFF;
}