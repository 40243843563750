.searchResultContainer {
    width: 607px;
    height: 341px;
    background-color: #030F17;
    color: #F1F2F2;
    margin-left: auto;
    margin-right: auto;
    margin-top: 14px;
    text-align: center;
    padding-left: 30px;
    padding-right: 30px;
}

.SearchNoData {
    text-align: center;
    margin: auto;
}

.anchorSearchPost {
    text-decoration: none;
}

.anchorSearchPost:first-of-type {
    margin-top: 15px;
}

.anchorSearchPost:last-of-type {
    margin-bottom: 15px;
}

.title_box {
    height: 38px;
    padding-top: 19px;
    padding-bottom: 11px;
}

.title_text {
    font-family: Jost-Regular, Sans-serif;
    font-weight: 500;
    font-size: 26px;
    line-height: 38px;
    color: #F1F2F2;
    text-align: left;
    float: left;
}

.searchShowMore {
    float: right;
    margin-top: 14px;
    margin-left: -10px;
}

.hashTagContainer {

    text-align: left;
    /* height: 25px;
    margin-top: 11px;
    margin-bottom: 7px; */
}

.hashTagText {
    font-family: Jost-Regular, Sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #8FA8B8;
    border: 1px solid #8FA8B8;
    padding: 4px 10px;
    border-radius: 3px;
    margin-right: 10px;
}

.searchItemBox.context {
    margin-top: 15px;
}

.searchItemBox {
    width: 607px;
    height: 49px;
    background: linear-gradient(270deg, #081B27 6.48%, rgba(8, 27, 39, 0.4) 105.09%);
    border-radius: 4px;
    margin-top: 9px;
    display: flex;
}

.imgTextDivider {
    border-right: 2px solid #030F17;
}

.searchItemImg {
    text-align: left;
    padding: 15px 13px;
}

.searchItemText {
    font-family: Jost-Regular, Sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    text-align: justify;
    color: #CFDEE8;
    margin-top: 14px;
    display: inline-block;
    margin-left: 17px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    content: "";
}

.searchItemTextFade {
    background: linear-gradient(90deg, #CFDEE8 75.48%, rgba(8, 27, 39, 0.4) 105.09%);
    -webkit-text-fill-color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
}

.searchFooter {
    margin-top: 22px;
}

.searchProfilePic {
    width: 30px;
    height: 30px;
    border: 1px solid #F1F2F2;
    border-radius: 50%;
    text-align: left;
    margin-right: 10px;
    float: left;
}

.noSearchProfilePic {
    padding: 5px;
    width: 20px;
    height: 20px;
}

.searchProfileName {
    font-family: Jost-Regular, Sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #F1F2F2;
    margin-top: 7px;
    float: left;
    margin-bottom: 20px;
}


.searchTime {
    float: right;
    font-family: Jost-Regular, Sans-serif;
    font-weight: 400;
    font-size: 13px;
    line-height: 19px;
    color: #8FA8B8;
    margin-top: 7px;
}

.title_box .menu-pop-up {
    left: 22px !important;
    width: 180px;
}

.searchBtn a {
    text-decoration: none;
}

.searchMoreImg {
    width: 35px;
    text-align: justify;
}

.searchMoreText {
    font-family: Jost-Regular, Sans-serif;
    font-weight: 400;
    font-size: 13px;
    line-height: 19px;
    color: #F1F2F2;
    text-transform: capitalize;
}

.labelDay {
    color: #CFDEE8;
    font-family: Jost-Regular, Sans-serif;
    font-size: 16px;
}

.textOnInput {
    position: relative;
}

.textOnInput label {
    position: absolute;
    top: -15px;
    left: 10px;
    padding: 2px;
    z-index: 1;
}

.textOnInput label:after {
    content: " ";
    width: 100%;
    height: 13px;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
}

label {
    font-size: 16px;
    font-weight: 500;
    display: inline-block;
    /* margin-bottom: .5rem; */
}

.form-controlabc {
    box-shadow: none !important;

}

#AdvSeachCalendar .react-datepicker__month-read-view,
#AdvSeachCalendar .react-datepicker__year-read-view {
    visibility: visible !important;
    font-family: Jost-Regular, Sans-serif;
    font-weight: 400;
    font-size: 13px;
    line-height: 13px;
}

.searchPopUpContainer .form-control {
    -webkit-text-fill-color: #8FA8B8;
    font-family: Jost-Regular, Sans-serif;
    font-weight: 400;
    font-size: 13px;
    line-height: 19px;
    background: #09202D;
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    color: #F1F2F2;
}
.searchPopUpContainer .form-control:focus {
    background: #0D2738;
}

.searchIconBtn {
    background: transparent;
    border: none;
    /* border: 1.4px solid #F1F2F2;     */
    box-sizing: border-box;
    border-radius: 42px;
    width: 64px;
    height: 36px;

}

.daysContainer {
    display: flex;
    column-gap: 35px;
}

.ml35 {
    margin-left: 35px;
}

.searchDateInput::before {
    position: absolute;
    content: " \2193";
    top: 0px;
    right: -8px;
    height: 20px;
    width: 20px;
}

.calOpen {
    column-gap: 21px;
}

.dropdownArrow {
    transform: rotate(135deg);
    border-color: #F1F2F2 !important;
    width: 0px !important;
    height: 0px !important;
    border: 1.5px solid #F1F2F2 !important;
    display: inline-block !important;
    padding: 1.5px !important;
    border-top-left-radius: 0rem;
    border-top-right-radius: 0rem;
    border-left-color: #081B27 !important;
    border-bottom-color: #081B27 !important;
    margin-left: -20px;
}

#thisContainsAutocomplete .MuiChip-root .MuiChip-avatar {
    margin-left: 0px !important;
    height: 31px !important;
    width: 31px !important;
    border: 1px solid #f2f2f2;
}

#thisContainsAutocomplete .MuiAutocomplete-option {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.searchFound {
    background: #012540;
}

.searchFound .searchItemBox {
    background: #081B27
}

.searchCheckText .MuiCheckbox-colorSecondary.Mui-checked {
    color: inherit !important;
}

.autoCompleteWrapper {
    background: #09202D;
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    width: 100%;
    padding-bottom: 0px
}

.autoCompleteBox {
    width: 100%;
    display: flex;
    align-items: center;
    padding-bottom: 6px;
    padding-top: 6px
}

.autoCompleteImg {
    height: 25;
    width: 25;
    border-radius: 50%;
    margin-right: 5px;
    border: 1px solid #F1F2F2;
}

.advSearchWrapper {
    display: flex;
    margin: 0px auto 20px;
    width: 668px;
}

.advSearchBox {
    background: #030F17;
    border: 1.6px solid #0F2C40;
    border-radius: 10px;
    padding: 24px 24px 28px;
    Width: 632px;
}

.titleFound {
    background: #0D2738;
    border: 1.6px solid #0D2738;
    border-radius: 10px;
}

.advTitle {
    font-family: Jost-Regular, Sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 29px;
    display: flex;
    align-items: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #F1F2F2;
    padding-bottom: 12px;
}

.advHashTagBox {
    padding: 5px 0px 9px;
    height: 24px;
}

.advHashTagText {
    font-family: Jost-Regular, Sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;    
    padding: 3.5px 12px;
    margin-right: 10px;
    border: 1px solid #86A2B4;
    filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.2));
    border-radius: 2px;
    color: #86A2B4;
}

.advTagFound {
    background: #0F2C40;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
}

.advImgDescBox {
    background: #091E2A;
    border-radius: 4px;
    height: 44px;
    width: 630px;
    display: flex;
    margin-top: 12px;
}

.advImgDescBoxDescOpen {
    background: #0D2738;
}

.titleFound .advImgDescBoxDescOpen{
    background: #091E2A;
}

.titleFound .advDetailedDesc{
    background: #091E2A;
}
.advSearchFound {
    background: #0D2738;
}

.titleFound .advSearchFound {
    background: #091E2A;
}
.advImgDescDivider {
    border-right: 2px solid #030F17;
    width: 44px;
}

.titleFound .advImgDescDivider {
    border-right: 2px solid #0D2738;
}

.advImgDescImg {
    text-align: left;
    padding: 13px;
}

.advImgDescKeyType {
    font-family: Jost-Regular, Sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #F1F2F2;
    margin-top: 14px;
    padding-left: 16px;
}

.advImgDescText {
    font-family: Jost-Regular, Sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    text-align: justify;
    color: #F1F2F2;
    margin-top: 14px;
    padding-left: 16px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    content: "";
}

.advDetailedDesc {
    background: #0D2738;
    border-radius: 0px 0px 4px 4px;
    padding: 20px;
    padding-bottom: 24px;
    font-family: Jost-Regular, Sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    text-align: justify;
    color: #F1F2F2;
    margin-top: 2px;
}

.advProfile {
    padding: 24px 0px 28px;
}

.advSearchProfileName {
    font-family: Jost-Regular, Sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #F1F2F2;
    margin-top: 7px;
    float: left;
}

.firstCustomIconBtn {
    margin-top: 20px;
    margin-bottom: 16px;
}

.customIconBtn:hover {
    width: 146px;
    background: #0D2738;
    border-radius: 0px 4px 4px 0px;
    padding: 10px 0px 12px 10px;
    text-align: left;
    align-items: center;
    display: flex;
}