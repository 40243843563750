.contactUsContainer {
  width: 912px;
  text-align: center;
  margin: 35px auto;
}

.contactUsContainer .profileInnerContainer {
  width: 558.23px;
  padding: 0px 120px;
}

.contactUsInnerContainer {
  justify-content: center;
  display: grid;
  padding: 21px 0px 67px;
}

.contactUsHeader {
  font-family: Jost-Regular, Sans-serif;
  font-weight: 400;
  font-size: 22px;
  line-height: 32px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #6A8393;
  margin: 60px auto 0px;
}

a {
  color: #F1F2F2;
}

a:visited {
  color: #86A2B4;
}


.infoBlock .contactUsText {
  font-family: Jost-Regular, Sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.02em;
  margin: 8px 0px 0px 19px !important;
  padding: 3px 6px;
  color: #F1F2F2;
}

.infoBlock .contactUsTexthref {
  font-family: Jost-Regular, Sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.02em;
  margin: 8px 0px 0px 19px !important;
  padding: 3px 6px;
}

.infoBlock .contactUsText:hover,
.infoBlock .contactUsTexthref:hover {
  background: #13344A;
  color: #81AACD;
  height: 26px;
}

.contactUsText.noHover:hover{
  background: none;
  color: #F1F2F2;
}

.infoBlock {
  display: flex;
  margin: 15px 0px;
}

.iconImgCircle {
  border-radius: 50%;
  border: 1.6px solid #F1F2F2;
  width: 48px;
  height: 48px;
}

.iconImg {
  margin-top: 6px;
  top: 8px;
  fill: #CFDEE8 !important;
}

.infoIconImg {
  height: 24px;
  width: 24px;
  margin-top: 12px;
}