.otherBox {
    height: 94;
    background: #030F17;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.75);
    border-Radius: 4px;
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    align-content: center;
    width: 550;
    padding-left: 25;
    padding-right: 25;
    transition: 'width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s';
}

.otherBoxPopUp {
    /* height: 50; */
    /* background: #030F17; */
    /* box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.75); */
    border-Radius: 4px;
    /* margin-bottom: 1rem; */
    display: flex;
    justify-content: space-between;
    align-content: center;
    width: 100%;
    padding-left: 5;
    padding-right: 5;
    transition: 'width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s';
}

.profilePic {
    border-radius: 50%;
    height: 66px;
    width: 66px;
    border: 1px solid #F1F2F2;
    margin-right: 25;
}

.profilePicDiv {
    display: flex;
    align-items: center;
    width: 100%
}

.profilePicDivPopUp {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 10px !important;
}

.profilePicDivPopUp .profileImg {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
    border: 1px solid #F1F2F2;
}

.profileImg.notiNoProfileImg{
    width: 30px;
    height:30px;
    padding:5px;
}

.notiMsg {
    font-family: Jost-Regular, Sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    color: #F1F2F2
}

.notiTime {
    font-family: Jost-Regular, Sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #6A8393;
    display: flex;
    flex-direction: row;
    justify-content: space-between
}

.threeDots .MuiTooltip-tooltipPlacementBottom,
.threeDots .MuiTooltip-tooltipPlacementTop {
    padding-right: 0 !important;
}

.threeDots .MuiTooltip-tooltip {
    max-width: none !important;
    width: 396px;
    width: max-content;

}

.popUpDots .MuiTooltip-tooltip {
    max-width: none !important;
    width: max-content;
}

.notiMsgPopUp {
    font-family: Jost-Regular, Sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 23px;
    color: #F1F2F2
}

.notiTimePopUp {
    font-family: Jost-Regular, Sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 23px;
    color: #6A8393;
    display: flex !important;
    flex-direction: row;
    justify-content: space-between;
}

.notiTimePopUp.onHoverNoti{
    display: block !important;
}
.threeDots {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: auto;
}

.acceptbtn {
    width: 80px;
    height: 30px;
    color: #F1F2F2 !important;
    margin-right: 10px !important;
    background-color: #081B27 !important;
}

.rejectbtn {
    width: 80px;
    height: 30px;
    color: #F1F2F2 !important;
    background-color: #081B27 !important;
}

.btnParent {
    margin-right: 25px
}

.w100 {
    width: 100%
}

.fadeOut {
    opacity: 0;
    width: 0;
    height: 0;
    transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.2s;

}

.fadeIn {
    opacity: 1;
    transition: width 0.5s, height 0.5s, opacity 0.5s 0.5s;

}

.friendRequestBtn{
    display: flex !important;
}
.verticalDropDownText{
    font-family: Jost-Regular, Sans-serif !important;
    display: flex;
    align-items: center;
    margin: 0;
    font-size:12;
}

/*dummy commit */