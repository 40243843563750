.custom-spinner {
	background-image: url('./SpinningLoader/LOADING_GIF_4.gif');
	background-repeat: no-repeat;
	background-position: center;
	width: 200px;
	height: 200px;
  }

.LOADING_GIF_4 {
	transform-box: fill-box;
	transform-origin: 50% 50%
}
.Ellipse_189 {
	animation: 1.3s linear 0s 1 normal forwards running Ellipse_189;
	transform-box: fill-box;
	transform-origin: 50% 50%
}
@keyframes Ellipse_189 {
	0% {
		transform: rotate(0deg);
	}
	23.08% {
		transform: rotate(90deg);
	}
	46.15% {
		transform: rotate(90deg);
	}
	69.23% {
		transform: rotate(90deg);
	}
	92.31% {
		transform: rotate(90deg);
	}
}
.Ellipse_189 {
	animation: 1.3s linear 0s 1 normal forwards running Ellipse_189;
	transform-box: fill-box;
	transform-origin: 50% 50%
}
@keyframes Ellipse_189 {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(90deg);
	}
	23.08% {
		transform: rotate(90deg);
	}
	46.15% {
		transform: rotate(90deg);
	}
	69.23% {
		transform: rotate(90deg);
	}
}
.Ellipse_189 {
	animation: 1.3s linear 0s 1 normal forwards running Ellipse_189;
	transform-box: fill-box;
	transform-origin: 50% 50%
}
@keyframes Ellipse_189 {
	0% {
		transform: rotate(0deg);
	}
	23.08% {
		transform: rotate(90deg);
	}
	46.15% {
		transform: rotate(90deg);
	}
	69.23% {
		transform: rotate(90deg);
	}
	92.31% {
		transform: rotate(90deg);
	}
}
.Ellipse_189 {
	animation: 1.3s linear 0s 1 normal forwards running Ellipse_189;
	transform-box: fill-box;
	transform-origin: 50% 50%
}
@keyframes Ellipse_189 {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(90deg);
	}
	23.08% {
		transform: rotate(90deg);
	}
	46.15% {
		transform: rotate(90deg);
	}
	69.23% {
		transform: rotate(90deg);
	}
}