.forgetPasswordContainer{
    max-width: 478px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 65px;
    padding: 35px 100px;
    background: #030F17;
    border: 1.6px solid #0F2C40;
    border-radius: 10px;
    filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.4));
}
.forgetPasswordHeading{
        font-family: Jost-Regular, Sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 32px;
        /* identical to box height */
        margin-top: 25px;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #6A8393;
}
.forgetPasswordIntruction{
    font-family: Jost-Regular, Sans-serif;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.04em;
    margin-top: 22.72px;
    color: #FFFFFF;
}
.forgetPasswordInputfeild{
    background: #09202D;
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    height: 44px;
    font-family: Jost-Regular, Sans-serif;
    /* margin: 20px 100px 0px 100px !important; */
    margin-top: 14.72px;

}
.forgetPasswordInputfeild::-webkit-input-placeholder {
       font-family: Jost-Regular, Sans-serif;
  }
  
  .forgetPasswordInputfeild:-ms-input-placeholder {
       font-family: Jost-Regular, Sans-serif;
  }
  
  .forgetPasswordInputfeild:-moz-placeholder {
       font-family: Jost-Regular, Sans-serif;
  }
  
  .forgetPasswordInputfeild::-moz-placeholder {
       font-family: Jost-Regular, Sans-serif;
  }
.forgetPasswordSubmit{
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.forgetPasswordSubmitBtn{
    border: 1.6px solid #F1F2F2;
    border-radius: 4px;
    height: 40px;
    background-color: inherit;
    color: #fff;
    width: 200px;
    margin:20px 140px 5px 140px;
    font-family: Jost-Regular, Sans-serif;
    font-size:13px;
    cursor: pointer;
}

.emailSuccessMessage{
    border-radius: 4px;
    margin-top: 14.72px;
    font-family: Jost-Regular, Sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
/* identical to box height */
    color: #F1F2F2;
}
.emailSuccessMessage .MuiAlert-standardSuccess {
    color: #fff;
    background-color: #13344A !important;
}
.emailErrorMessage .MuiAlert-standardSuccess{
    background-color: red;
    color: #fff;
}
.emailSuccessMessage .MuiAlert-standardSuccess .MuiAlert-icon {
    width: 20px;
    color: inherit !important;
}
/* .errorMessageContainer .MuiCollapse-root{
    margin: 0px 100px 0px 100px !important;
} */
.emailEnterContaner{
   width: 480px;
}
.MuiAlert-message{
    font-family: Jost-Regular, Sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: #F1F2F2;
    padding: 7px 0 !important;
}
@media only screen and (max-width: 767px) {
    .forgetPasswordContainer{
        width: auto  !important;
        height: auto !important;
        margin: 12px !important;
    }
    .forgetPasswordInputfeild{
        margin: 12px !important;
    width: auto !important;
    }
    .forgetPasswordSubmitBtn{
        width: 90%;
    margin: 20px;
    }
    .emailSuccessMessage{
        width: auto !important;
        margin: 12px !important;
    }
    .emailEnterContaner{
        width: auto !important;
    }
    .errorMessageContainer .MuiCollapse-root {
        margin: 0 10px;
    }
}
