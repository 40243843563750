.notificationPopUpContainer {
    width: 320px !important;
    height: auto;
    border-radius: 4px;
    /* display: flex; */
    background: #030F17 !important;
}

.notificationPopUpConHeight {
    height: auto;
}

.unreadnotificationPopUpContainer {
    background: #09202F !important;
    width: 320px !important;

    border-radius: 4;
    display: flex;
    justify-content: space-between;
}

.circularProgress {

    height: 146px;
    width: 680px;
    border-radius: 10px;
    background: #030F17;
    margin-top: 12;
    border: 1.6px solid #0F2C40;

}

.noPendingNotification {
    padding: 60px;
    text-align: center;
    font-family: Jost-Regular, Sans-serif;
    font-weight: 400;
    color: #86A2B4;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
}


.frindRequestBtn {
    width: 100px;
    margin-bottom: 16px !important;
    height: 32px;
    border-radius: 4px;
    background: #0D2738;
}

.rejectbtnfeed {
    /* width: 100px; */
    border: none !important;
    height: 32px;
    background: #041926 !important;
    border-radius: 4px;
    margin-right: 12px !important;
    text-transform: uppercase;
}

.feedrejectContact {
    font-family: Jost-Regular, Sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;

    color: #86A2B4;
}

.feedrejectContact .MuiSvgIcon-root {
    width: 16px !important;
    height: 16px !important;
}

.feedAddContact .MuiSvgIcon-root {
    width: 16px !important;
    height: 16px !important;
}

.rejectbtnfeed p span {
    font-family: Jost-Regular, Sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    margin: auto;
    color: #86A2B4;
}

.feedAddContact span {
    font-family: Jost-Regular, Sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    margin: auto;
    color: #F1F2F2;
}

.acceptbtnfeed {
    height: 32px;
    background: #0D2738 !important;
    border-radius: 4px;
    text-transform: uppercase;

}

.notificationPopUpContainerPage {
    width: 592px;
    /* height: 92px; */
    background: #030F17;
    padding: 12px;
    border-radius: 4px;
    /* display: flex; */
}

.notificationPopUpContainerPage:hover {
    background-color: #09202F;
}

.profilePicDivPopUpFeed {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 12px;
}

.profilePicDivPopUp .profileImg {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
    border: 1px solid #F1F2F2;
}

.profileImg {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
    border: 1px solid #F1F2F2;
}

.profilePicDivPopUp .profileImgNotify {
    width: 66.52px;
    height: 66.52px;
}

.threeDotTooltipContainer1 .MuiTooltip-popperArrow {
    margin: 0px 24px;
    /* background-color: #0C2B3D; */
}

.threeDotTooltipContainer .MuiTooltip-tooltipPlacementRight {
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2) !important;
    left: 12;
    top: -20px;
}

.friendReqThreeDot {
    margin: 16px 10px 16px 0px !important;
}

.threeDotTooltipContainer .MuiTooltip-popperArrow[x-placement*="bottom"] .MuiTooltip-arrow {
    /* margin-left: 48px !important; */
    left: 200px !important;
}

.threeDotTooltipContainer .MuiTooltip-tooltipPlacementBottom {
    right: -9 !important;
}
