body {
  font-size: 16px;
}

.AddNewIdeaBox {
  max-width: 630px;
  width: auto;
  color: #f1f2f2;
  height: auto;
  padding: 24px 24px 30px;
  font-family: Jost-Regular, Sans-serif !important;
  font-weight: 400;
  background: #030F17;
  border: 1.6px solid #0F2C40;
  border-radius: 10px;
  margin: 36px auto;
}

.AddNewIdeaBox .form-control {
  border-radius: 0px 0px 4px 4px;
}

.AddNewIdeaBox .form-control .Mui-focused {
  background: #0D2738;
  /* border: 1px solid #284A62; */
}

.AddNewIdeaBox .LabelBox {
  padding: 10px 16px;
  background: #091E2A;
}

.AddNewIdeaSelect {
  width: 100%;
  max-width: 120px;
  max-height: 40px;
  color: #f1f2f2 !important;
  background-color: #081b27;
  border-radius: 4px !important;
  height: 39px !important;
  border: none;
  /* padding: 11px 18px; */
  font-family: Jost-Regular, Sans-serif;
  font-weight: 400;
  margin-left: auto;
  margin-right: 0;
}

.AddNewIdeaInput {
  width: 100%;
  max-width: 500px;
  color: #F1F2F2 !important;
  background-color: #091E2A;
  border-radius: 4px !important;
  height: 40px !important;
  border: none;
  padding: 9px 20px;
  font-family: Jost-Regular, Sans-serif !important;
  font-weight: 400;
  margin-left: 0;
  margin-right: 0;
}

.titleAdded {
  background: #0D2738;
}

.AddNewIdeaInput .MuiInputBase-input {
  line-height: 22px;
}

.AddNewIdeaBox .addTitleBox .Mui-focused {
  background: #0D2738;
  margin-right: -0.5px;
}

.addTitleBox {
  margin-bottom: 24px;
}

#title::-webkit-input-placeholder,
#context::-webkit-input-placeholder,
#problem::-webkit-input-placeholder,
#solution::-webkit-input-placeholder {
  color: #f1f2f2;
  opacity: 1;
  font-family: Jost-Regular, Sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
}

/* Below code is for Select dropdown */
.work {
  background-color: #081B27 !important;
  width: 120px !important;
  left: -10px;
  font-family: Jost-Regular, Sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: #F1F2F2 !important;
  margin-top: 42px;

}
.AddNewIdeaDropDownItem{
  height: 40px;
}


.AddNewIdeaDropDownItem:focus {
  background-color: inherit !important
}
.AddNewIdeaDropDownItem:hover {
  background-color: #13344A !important
}

.drop-down-image {
  margin-right: 9.3px;
  width: 16px;
  height: 16px;
}

.profileImage {
  position: absolute;
  z-index: 1;
  left: -89px;
  top: -0.65rem;
  width: 48px;
  height: 48px;
  border-radius: 50%;
}

.profileImage img {
  border-radius: 50%;
  border: 1px solid #F1F2F2;
  height: 46px;
  width: 46px;
}

.LabelBox {
  width: auto;
  color: #f1f2f2;
  background-color: #081b27;
  border-radius: 4px 4px 0px 0px;
  height: auto;
  border: none;
  padding: 15px 18px;
  font-family: Jost-Regular, Sans-serif;
  font-weight: 400;
  margin-bottom: 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.editedIdea .form-control {
  background: #0D2738;
}

.editedIdea .LabelBox {
  background: #0D2738;
}

.editedIdea .MuiInputBase-root {
  background: #0D2738 !important;
}

.info-icon {
  height: 13px;
}

.center {
  display: flex;
  margin: auto;
  justify-content: center;
}

.footer-icon {
  height: 30px;
  width: 30px;
  margin-top: 5px;
  margin-left: 9px;
  margin-right: 9px;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .AddNewIdeaInput {
    width: 100%;
  }

  .AddNewIdeaBox {
    width: 80%;
  }

  .smbottom {
    flex-direction: column;
    width: 210%;
  }

  .smaller-input {
    width: 100%;
  }

  body {
    font-size: 14px;
  }

  .sm-view {
    flex-direction: column;
    width: 100%;
    position: relative;
    z-index: 1;
  }

  .front-input {
    width: 100% !important;
  }

  .max-width-100 {
    resize: none;
  }

  .AddNewIdeaSelect {
    width: 100% !important;
    /* justify-content: flex-start; */
    max-width: none;
  }

  .addNewIdeaSelectText {
    justify-content: flex-start !important;
    align-items: flex-start !important;
  }

  .gap {
    grid-gap: 1rem !important;
    gap: 2rem !important;
  }

  .profileImage {
    display: none;
  }

  .drop-down-image {
    padding-left: 20px;
    margin-right: 5%;
  }

  .arrow-left:before {
    display: none;
  }

  .feedarrow-left:before {
    display: none;
  }
}

.sm-view {
  gap: 2rem;
}

.box {
  width: 150px;
  height: 75px;
  background-color: black;
  color: #fff;
  padding: 20px;
  position: relative;
  margin: 40px;
  float: left;
}

.arrow-left {
  position: relative;
}

/* .arrow-left:before {
  content: "";
  position: absolute;
  left: -3.5rem;
  border-top: 20px solid transparent;
  border-right: 20px solid #030f17;
  border-bottom: 20px solid transparent;
} */

.arrow-up {
  width: 32px;
  height: 20px;
  background-color: #8888e8;

  clip-path: polygon(50% 0, 0 100%, 100% 100%);
}

.arrow {
  position: absolute;
  top: 10%;
  left: 70%;
  width: 0;
  height: 0;
  border-width: 0 20px 25 20px;
  border-color: transparent transparent #ff0000 transparent;
  border-style: solid;
  z-index: 1;
}

#textarea-resize-none {
  resize: none;
}

.box-input {
  background-color: #081b27;
  color: white;
}

.bottom-margin-35 {
  margin-bottom: 35px;
}

.bottom-margin-30 {
  margin-bottom: 30px;
}

.flex-box {
  display: flex;
  gap: 16px;
}

.gap {
  gap: 2rem !important;
}

.justify-content {
  justify-content: space-between;
}

/* Fixing the height of the Context,Problem,Solution input box */
.form-control .MuiInput-multiline {
  padding: 3.5px 1.375rem;
  flex-direction: column;
}

#problem,
#solution,
#context {
  margin-bottom: 6.09px;
  font-family: Jost-Regular, Sans-serif;
  font-style: normal;
  word-break: break-word;
}

.no-margin {
  margin: 0px;
}

.dropdown-padding option {
  padding: 10px;
}

.smaller-input {
  width: 100%;
  max-width: 345px;
}

.dropdown {
  position: "absolute";
  top: 28px;
  right: 0;
  left: 0;
  z-index: 1;
  border: "1px solid";
}

.plus-icon {
  padding-top: 3px;
}

.plus-icon:hover {
  cursor: pointer;
}

.MuiTooltip-popper {
  /* width: 25%; */
  min-width: 140px;
}

/* Below code is for Tooltip

Starts here */

.MuiTooltip-tooltipPlacementRight {
  background-color: #012540 !important;
  /* box-shadow: 0px 0px 5px #000000 !important; */
  font-family: Jost-Regular, Sans-serif !important;
  font-size: 16px !important;
  text-align: justify;
  /* padding-right: 50px !important; */
}

.MuiTooltip-tooltipPlacementBottom,
.MuiTooltip-tooltipPlacementTop {
  background-color: transparent !important;
  /* box-shadow: 0px 0px 5px #000000 !important; */
  font-family: Jost-Regular, Sans-serif !important;
  font-size: 16px !important;
  text-align: justify;
  /* padding-right: 30px !important; */
  /* width: 70%;
  min-width: 120px; */
}

.customToolTip {
  background-color: #0D2738 !important;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  font-family: Jost-Regular, Sans-serif !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  text-align: justify;
  padding: 20px 20px 20px 16px !important;
  width: 244px;
}

.MuiTooltip-tooltipArrow ul:first-child {
  margin-bottom: 10px;
}


.MuiTooltip-arrow::before {
  background-color: #012540 !important;
  /* box-shadow: 0px 0px 5px #000000 !important; */
}

/* Ends here */

/* Below Code is for Menu Pop up (after clicking plus icon) */
.menu-pop-up,
.menu-pop-up .menu-pop-up-arrow::before {
  padding-right: 0px !important;
  background-color: #081b27 !important;
}

.menu-pop-up .menu-pop-up-arrow::before {
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.4);
}

.MuiBox-root-1 {
  margin: 1px !important;
}

.MuiTooltip-tooltip {
  padding: 1px 1px;
}

.height-auto {
  height: auto !important;
  color: "white" !important;
}

.MuiInputBase-multiline .MuiInputBase-input {
  font-family: Jost-Regular, Sans-serif;
  font-style: normal;
  text-align: justify;
  color: #F1F2F2;
  min-height: 30px !important;

  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.feedContent .MuiInputBase-multiline .MuiInputBase-input {
  /* padding-bottom: 10px; */
  /* min-height: 81.1px !important; */
  word-wrap: break-word;
  word-break: break-all;
}

.mainFeedContent .MuiInputBase-multiline .MuiInputBase-input {
  min-height: 100px !important;
}

.AddNewIdeaBox .form-control .MuiInput-multiline {
  padding: 20px 20px 23px;
  color: #F1F2F2;
  background: #091E2A;
}

.MuiInputBase-multiline .MuiInputBase-input ::placeholder {
  color: #F1F2F2 !important;
}

.text-field-Adornment {
  place-self: end;
  color: #6a8393;
  font-family: Jost-Regular, Sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  padding-bottom: 0.8rem;
  margin-right: -0.6rem;
}

.text-color-white {
  color: white !important;
}

.Error {
  background-color: #B00002 !important;
  color: white !important;
}

.previewImage {
  width: 100%;
  object-fit: cover;
  height: 120px;
}

.contextPreviewImage {
  width: 100%;
  object-fit: cover;
  height: 212px;
  border-radius: 4px;
}

.addNewIdeaTitleBoxFont18 {
  font-size: 18px !important;
  line-height: 26px;
  letter-spacing: 0.08em;
}

.toolTipUL {
  padding-left: 20px;
  margin: 0px;
}

.errInfoIcon circle {
  fill: #F1F2F2;
  opacity: 0.5;
}

.errInfoIcon path {
  fill: #F1F2F2;
}

.img-center {
  display: flex;
  align-items: center;
}

.CustomErrorOverwrite {
  margin-bottom: 24px;
}

.CustomErrorOverwrite .ErrorArrowTop:after {
  content: none;
}

.addNewIdeaSelectText {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Jost-Regular, Sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: #f1f2f2;
}

.AddNewIdeaSelect svg {
  height: 70%;
  right: 10px;
}

.SelectMenuItem {
  margin: 0 !important;
  font-size: 12;
}

.w-15 .MuiTooltip-tooltip {
  padding: 2 !important;
}

.w-15 .MuiTooltip-popper {
  width: auto;
  min-width: 180px;
}

.LabelBox .form-label {
  align-items: center;
  min-width: 148px;
  justify-content: space-between;
  height: 24px;
}

.MuiPopover-paper {
  border-radius: 0px !important;
}

.addNewIdeaTooltip .menu-pop-up,
.addNewIdeaTooltip .menu-pop-up .menu-pop-up-arrow::before {
  background-color: #0D2738 !important;
}

.boxSeparator {
  margin-bottom: 16px;
}