.center {
  display: flex;
  margin: auto;
}

.searchCalendarContainer {
  width: 143px;
  height: 134px;
  background: #081b27;
  box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 10px 12px;
}

.searchCalendarInnerContainer {
  display: flex;
  justify-content: space-around;
}

.EditBox {
  width: 41px;
  height: 20px;
  text-align: center;
  background: #030f17;
  border-radius: 2px;
  color: #f1f2f2;
  font-family: Jost-Regular, Sans-serif;
  font-weight: 400;
  font-size: 9px;
  line-height: 13px;
  border: none;
}

.EditBox.month {
  /* background: #012540; */
  height: 16px;
  padding-top: 4px;
}

.calError {
  border: 2px solid #b30507 !important;
}
.monthContainer {
  /* column-count: 3;
    text-align: center;*/
  margin-top: 13px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-left: 10px;
}

.monthContainer .monthName {
  font-family: Jost-Regular, Sans-serif;
  font-weight: 400;
  font-size: 9px;
  line-height: 13px;
  color: #f1f2f2;
  text-align: center;
  width: 35px;
  height: 18px;
  margin-bottom: 0px;
  margin-top: 7px;
  min-width: auto;
  text-transform: capitalize;
  /* background: #012540; */
}

.monthContainer .monthName:hover {
  background: #012540;
  border-radius: 2px;
}

div[data-placeholder]:not([data-placeholder=""]):empty::before {
  content: attr(data-placeholder);
  color: #8FA8B8;
}

input[type="number"]:focus {
  border: none;
}
input:focus-visible {
  outline: none;
  border-radius: 3px;
}

.searchBtn .MuiButton-root.Mui-disabled{
  opacity: 0.5;
}