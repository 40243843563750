.web_ContainerLogined {
    background: #081C28;
    height: 100vh !important;
    width: 100vw !important;
}

.web_Container {
    background: radial-gradient(rgb(13, 40, 57), rgb(3, 15, 23));
    /* background-color: #061823; */
    height: 100vh !important;
    width: 100vw !important;
    filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.4));
}

.webHeader .MuiContainer-root {
    padding: 0px;
    max-width: inherit;
}

.feedBackChipButton .MuiIconButton-label {
    justify-content: center !important;
}

::-webkit-scrollbar {
    width: 5px;
    background: #0B2534;
    border-radius: 4px;
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.25);
}

::-webkit-scrollbar:horizontal {
    height: 5px;
    background: #0B2534;
    border-radius: 4px;
    display: none;
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.25);
}