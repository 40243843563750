.profileMainContainer {
  width: 912px;
  text-align: center;
  margin: 35px auto;
}

.EditprofileInnerContainer {
  background: none !important;
  width: 800px;
}

.profileContainer {
  background: #030f17;
  margin: auto;
  width: 800px;
  text-align: center;
  border-radius: 10px;
  border: 1.6px solid #0F2C40;
}

.backgroungImgContainer {
  position: relative;
  height: 182px;
  background: #081b27;
  border-radius: 10px 10px 0px 0px;
}

.basicAddImg {
  padding-top: 46px;
  max-height: 252px;
  max-width: 800px;
  position: relative;
}
.basicAddImgEditIcon{
  position: absolute !important;
  top: 20px;
  background: #fff !important;
  right: 20px;
  color:black;
}
.backAddImg {
  cursor: pointer;
  z-index: -15;
  border-radius: 10px 10px 0px 0px;
  min-height: 182px;
  max-height: 182px;
  width: 800px;
}

.profilePicBasicContainer {
  margin-top: -82px !important;
}

.profilePicContainer {
  margin-top: 30px;
}

.profilePicContainer img {
  width: 140px;
  height: 140px;
}

.profilePicContainerDailog img{
 width: 300px;
 height: 300px;
}

.profilePicSmall {
  height: 56px !important;
  width: 56px !important;
  background: #081b27;
}

.noProfilePic{  
  border: 1.4px solid #F1F2F2;
  border-radius: 50%;
  overflow: hidden;
  background-color: #081b27;
}
.noProfilePicDailog{  
  width: 300px;
  height: 300px;
  border: 3.6px solid #F1F2F2;
  border-radius: 50%;
  overflow: hidden;
  background-color: #081b27;
}
.inValideProfileImg{
  width: 300px;
  height: 300px;
  border: 3.6px solid red;
  border-radius: 50%;
  overflow: hidden;
  background-color: #081b27;
}
.detailContainer {
  padding: 0px 32px;
  padding-top: 16px;
  text-align: left;
}

.threeLabelContainer {
  display: flex;
  padding-bottom: 20px;
  justify-content: space-between;
}

.width50 {
  width: 354px;
}

.width33 {
  width: 232px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-10 {
  padding-top: 10px;
}

.labelName {
  font-family: Jost-Regular, sans-serif;
  font-weight: 400;
  font-size: 17px;
  line-height: 25px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #86A2B4;
  padding-bottom: 10px;
}

.backgroundDescription {
  height: 112px !important;
  resize: none;
}

.divider {
  height: 152px !important;
  margin: 0px 60px !important;
  border: 1px solid #86A2B4 !important;
  fill: #86A2B4;
}

.summaryContainer {
  background: #030f17;
  width: 800px;
  margin: 15px auto;
  border: 2px solid #0F2C40;
  border-radius: 10px;
  text-align: left;
}

.summaryNameContainer {
  padding: 40px 32px 0px;
}

.summaryInnerContainer {
  display: flex;
  padding: 20px 32px 40px 32px;
}

.list {
  display: block !important;
  width: 50% !important;
  margin-top: 0px !important;
}

.summaryInnerContainer .MuiBox-root-1 {
  margin: 0px 35px 0px 0px;
}

.summaryInnerContainer .MuiBox-root-2 {
  margin: 0px 0px 0px 35px;
}

.summaryLabel {
  font-family: Jost-Regular, sans-serif !important;
  font-weight: 400 !important;
  font-size: 15px !important;
  line-height: 22px !important;
  color: #f1f2f2 !important;
}

.summaryValue {
  font-family: Jost-Regular, sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #6a8393;
}

.deleteContainer {
  background: #030f17;
  width: 800px;
  margin: 15px auto;
  border: 2px solid #0F2C40;
  border-radius: 10px;
}

.deleteInnerContainer {
  padding: 40px 32px;
}

.deleteDialogTitle .MuiDialog-paper {
  width: 680px;
  max-width: 680px;
  background: #061621;
  border: 1.5px solid #193A51;
  border-radius: 8px;
  color: #FFFFFF;
}

.deleteDialogTitle .MuiDialogContent-root{
  padding: 12px 24px 0px;
}

.deleteDescription {
  font-family: Jost-Regular, sans-serif !important;
  font-size: 16px !important;
  line-height: 23px !important;
  letter-spacing: 0.04em !important;
  margin-top: 8px !important;
  text-align: center;
}

.deletePassword {
  justify-content: center;
  display: flex;
  margin-top: 26px;
}

.deletePassword .form-group{
  width: 480px;
}
.deleteButton {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.Component-closeButton-4 {
  color: #F1F2F2 !important;
}

.confirmationTitle {
  font-family: Jost-Regular, sans-serif !important;
  font-weight: 400 !important;
  font-size: 22px !important;
  line-height: 32px !important;
  letter-spacing: 0.1em !important;
  text-transform: uppercase;
  color: #6A8393;
  margin-top: 44px !important;
  text-align: center;
}

.form-group select {
  width: 232px;
}

.selectControl {
  width: 100%;
  color: #f1f2f2;
  background-color: #081B27;
  border-radius: 4px;
  height: 44px;
  font-size: 15px;
  font-family: Jost-Regular, sans-serif;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25) inset;
  border: none;
  padding: 11px 18px;
  cursor: pointer;
}
.selectControlError{
  border:1px solid #b30507;

}

.deletePopUpContainer {
  justify-content: center !important;
  padding: 0px 8px 20px !important;
}

#inputinuserblock {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
}

.deleteAlertContainer {
  width: 180px;
  overflow-x: hidden;
  margin: auto;
  padding-bottom: 20px;
}

.deleteAlertContainer .MuiAlert-icon {
  color: #cfdee8 !important;
}

.deleteAlertContainer .MuiAlert-standardSuccess {
  background-color: #061621 !important;
}

.date_input_error{
  font-family: Jost-Regular, sans-serif;
  margin-bottom: 0;
  color: #b30507;
}

.dailogImageUpload .MuiDialog-paperScrollPaper{
  width: 744px;
  background-color: #081B27;
  border-radius: 10px;
}

.dailogImageUploadDailog  .MuiDialog-paperScrollPaper{
  width: 744px !important;
  background-color: #081B27;
    
}
.optionClasses{
  cursor: pointer !important;
}


.MuiIconButton-colorPrimary {
    color: transparent;
}

.f15{
  cursor: pointer;
}
.optionClasses:hover{
  cursor: pointer !important;
}

.sideBar{
  margin-top: 20px;
}

.greyText{
  color: #86A2B4;
}

.nameTitle{
  display: flex;
  flex-direction: column;
  padding-bottom: 32px;
}

.labelEmail{
  color: #86A2B4;
  font-size: 13px;
  font-weight: 400;
  line-height: 19px;
  text-shadow: 0px 0px 5px #000000;
  font-family: Jost-Regular,sans-serif;
}

.labelFullName{
  color: #F1F2F2;
  font-size: 22px;
  font-weight: 500;
  line-height: 32px;
  text-shadow: 0px 0px 5px #000000;
  font-family: Jos-Regular, sans-serif;
}

.moreIconDiv{
  height: 40px;
  width: 40px;
  background: #091E2A;
  border-radius: 4px;
  margin-top: 9px;
}

.moreIcon{
  margin-top: 9px;
  margin-left: 13px;
  color: #F1F2F2;
  font-weight: 800;
}

.ProfileBoxFullName{
  font-family: Jost-Regular, sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  color: #F1F2F2;
}

.ProfileBoxUserName{
  font-family: Jost-Regular, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  color: #6A8393;
}

.AddContactDiv{
  margin-top: 18;
  width: 118px;
  max-width: 118;
  height: 32px;
  background: #030F17;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 'none';
}

.disabledBtn{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 18;
  width: 118px;
  max-width: 118;
  height: 32px;
  filter: blur(0.5px);
}

.IdeaDiv{
  margin-top: 18;
  width: 78px;
  max-width: 78;
  height: 32px;
  background: #030F17;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;
}

.AddContactText{
  line-height: 19px;
  color: #F1F2F2;
}

.AnonymousProfileBoxImage{
  position: absolute;
  top: -5;
  right: -45;
  height: 100px;
  width: 100px;
  border-radius: 50%;
}

.backAddImg.noBackImg{
  width: 48px;
  object-fit: contain;
}

.noProfilePicOrImg{
  padding: 51.5px;
}